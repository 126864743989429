@charset "UTF-8";

@import '../global/colors';
@import "../global/variables";
@import '../mixins/utils';
@import '../global/fontVariables';
@import '../mixins/fontProp';
@import '../mixins/fonts';
@import '../mixins/links';
@import "bootstrap/scss/mixins/breakpoints";

.container {
    width: 100%;

    @include media-breakpoint-down(md) {
        padding-bottom: 24px;
    }
}

.menu {
    ul,
    li {
        list-style: none;
    }

    a,
    a:hover,
    a:focus {
        text-decoration: none;
    }

    .navbar {
        padding: 0 12px 15px;
    }

    .menu-toggleable-left {
        overflow-x: scroll;

        .logo {
            padding-top: 4px;
            top: 8px;
            display: block;
            left: 0;
            margin-left: 60px;

            svg.newLogo-svg {
                height: 24px;
                width: 124px;
                margin-top: 7px;
            }
        }

        .close-menu {
            background-color: $transparent;
            border-bottom: 1px solid #d5d5d5;
            padding: 15px 0;
            padding-right: 16px;
            padding-bottom: 30px;
            padding-top: 16px;
        }

        .menu-group {
            width: 100%;
        }

        &.in {
            .nav-item {
                .nav-link {
                    @include fontSize($font-body2);
                }

                &.normal-link {
                    &:nth-child(4) {
                        .nav-link {
                            padding-bottom: 24px;
                        }
                    }
                }

                + .nav-item {
                    &.info {
                        display: flex;
                        align-items: center;
                        background: $gray;
                        margin: 0 16px 12px;
                        padding: 12px 16px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        svg {
                            width: 16px;
                            height: 16px;
                            margin-top: 1px;
                            margin-right: 12px;
                        }

                        p {
                            margin-bottom: 0;
                            letter-spacing: 0;
                            line-height: 1.4;
                            color: $black;
                            @include fontSize($font-body3);

                            &.info-day {
                                font-weight: 600;
                            }

                            a {
                                border-bottom: 1px solid $black;
                            }
                        }
                    }

                    &.normal-link:last-child {
                        border-bottom: 1px solid $light-gray;
                    }
                }
            }
        }
    }

    .navbar-nav {
        .nav-primary {
            letter-spacing: 0.075rem;
        }

        .nav-secondary {
            letter-spacing: 1.5px;
        }

        .nav-primary,
        .nav-secondary {
            text-transform: uppercase;
            color: $black;
            padding: 16px 0;
            font-weight: 600;
            @include fontSize($font-text);

            @include media-breakpoint-down(md) {
                text-transform: capitalize;
                font-weight: 400;
            }

            .bigPlus,
            .bigMinus,
            .minus,
            .plus {
                float: right;
                width: 16px;
                height: 17px;
                margin-top: 3px;

                @include media-breakpoint-up(md) {
                    height: 19px;
                    margin-top: 0;
                }
            }

            .bigPlus,
            .plus {
                display: none;
            }

            &.collapsed {
                .bigPlus,
                .plus {
                    display: block;
                }

                .bigMinus,
                .minus {
                    display: none;
                }
            }
        }

        .nav-primary {
            font-weight: 600;
            border-bottom: 1px solid $light-gray;

            @include media-breakpoint-down(md) {
                font-weight: 400 !important;
            }
        }

        .saleRed {
            .nav-primary {
                color: #d21e2f !important;
            }
        }

        .nav-secondary {
            font-weight: 600;

            @include media-breakpoint-down(md) {
                font-weight: 400px;
            }

            .nav-link {
                padding: 0;
            }

            &:hover .ctaArrow {
                transform: translateX(50%);
            }
        }

        .tertiary {
            border-top: none;
            border-bottom: none;
            border-color: $light-gray;

            .nav-link {
                @include fontSize($font-body2);

                letter-spacing: 1.3px;
                color: $black;
                padding-left: 0 !important;
                border-bottom: none;

                @include media-breakpoint-down(md) {
                    letter-spacing: normal;
                }
            }
        }

        .secondary {
            .menu-banner,
            .promo-tile-element {
                display: block;
                padding-left: 16px;
                padding-right: 16px;
                padding-top: 16px;

                @include media-breakpoint-down(md) {
                    padding-left: 5px;
                    padding-right: 7px;
                }

                @include media-breakpoint-up(lg) {
                    display: none;
                }

                @include media-breakpoint-up(xl) {
                    display: block;
                }

                img {
                    width: 100%;
                    height: 168px;
                    border-radius: 4px;
                    object-fit: cover;
                }

                p {
                    color: #000;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 20px;
                    @include fontSize($font-xsmall);
                }

                p > a {
                    color: #000;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 20px;
                    @include fontSize($font-body2);
                }
            }

            .close-menu-link {
                position: absolute;
                z-index: 10001;
                top: 5px;
                right: 0;
                padding: 16px 0 16px 16px;
            }
        }

        @include media-breakpoint-down(md) {
            @-webkit-keyframes slide-in {
                100% { right: 0; }
            }

            @keyframes slide-in {
                100% { right: 0; }
            }

            @-webkit-keyframes slide-out {
                100% { right: -400px; }
            }

            @keyframes slide-out {
                100% { right: -400px; }
            }

            .secondary,
            .tertiary {
                padding-left: 0;

                .other-header {
                    font-weight: 600;
                    border-bottom: 1px solid #d5d5d5;
                    text-transform: uppercase;
                    color: #000;
                    padding-top: 16px;
                    padding-bottom: 16px;
                    padding-left: 16px;
                    @include fontSize($font-xsmall);

                    @include media-breakpoint-down(md) {
                        text-transform: capitalize;

                        &.location-header {
                            text-transform: none;
                        }
                    }

                    span {
                        padding: 16px 0;
                    }
                }

                .nav-secondary {
                    font-weight: 600;
                    text-transform: uppercase;
                    color: #000;
                    padding: 16px 0;
                    @include fontSize($font-xsmall);

                    @include media-breakpoint-down(md) {
                        text-transform: capitalize;
                        line-height: 24px;
                        font-weight: 400;
                        display: block;
                    }
                }
            }

            .secondary {
                position: absolute;
                top: 0;
                right: -400px;
                width: 100%;
                height: 100%;
                background: #fff;
                -webkit-animation: slide-in 0.5s forwards;
                -webkit-animation-delay: 0.5s;
                animation: slide-in 0.5s forwards;
                animation-delay: 0.5s;
                z-index: 1000;

                .nav-item {
                    margin-left: 16px !important;
                    margin-right: 16px;
                    border-bottom: 1px solid #d5d5d5;
                    padding-top: 16px;
                    padding-bottom: 16px;

                    &.secondary-nav-li {
                        padding: 0;

                        .nav-item {
                            &:first-child {
                                padding-top: 0;
                            }
                        }
                    }
                }

                span svg {
                    margin-right: 16px;
                }
            }

            .primary {
                .nav-item {
                    border-bottom: 1px solid #d5d5d5;

                    .nav-primary {
                        padding-left: 16px;
                        padding-right: 16px;
                        color: #000;
                        letter-spacing: 0;
                        line-height: 44px;
                        @include fontSize($font-xsmall);

                        @include media-breakpoint-down(md) {
                            line-height: 24px;
                        }
                    }
                }
            }

            .tertiary {
                .nav-link {
                    padding-top: 0;
                    padding-left: 0;
                }

                .nav-item {
                    border-bottom: none;
                    border-color: #fff;

                    @include media-breakpoint-down(md) {
                        padding-bottom: 0;
                    }
                }

                &.in {
                    .nav-item {
                        .nav-link {
                            padding: 16px;
                            @include fontSize($font-body2);
                        }

                        + .nav-item {
                            border: none;
                            border-top: none;
                        }
                    }
                }
            }

            .secondary-hide {
                right: 0;
                -webkit-animation: slide-out 0.5s forwards;
                -webkit-animation-delay: 0.5s;
                animation: slide-out 0.5s forwards;
                animation-delay: 0.5s;
            }
        }
    }
}

.main-menu.menu-toggleable-left {
    @include media-breakpoint-down(md) {
        background-color: $white;
        z-index: $zindex-higher + 1;
        display: none;

        &.in {
            display: block;
        }
    }
}

.menu-toggleable-left {
    .close-menu {
        @include media-breakpoint-up(md) {
            display: block;
        }
    }
}

@include media-breakpoint-only(md) {
    .nav-item > .nav-link {
        color: inherit;
    }
}

@include media-breakpoint-up(lg) {
    .global-header .header .navigation {
        padding: 0;

        .navigation-container {
            .header-right {
                padding-top: 8px;
            }
        }
    }

    .outer-header-container {
        .banner {
            .slide-up {
                .header-promotion {
                    @include fontSize($font-body3);
                }
            }
        }
    }

    .menu {
        .navbar-header,
        .navbar {
            position: static;
        }

        .main-menu {
            &.menu-toggleable-left {
                overflow: visible;
                min-width: 440px;
                padding-top: 19px;
                margin-left: 124px;

                &:lang(ko),
                &:lang(ja) {
                    padding-top: 14px;
                }

                &:lang(zh) {
                    padding-top: 16px;
                }
            }
        }

        .primary {
            padding: 0 20px;
            flex-wrap: nowrap;

            > li.hover-disabled:hover .collapse.secondary,
            > li.tabActive .collapse.secondary {
                display: flex;
            }

            > li .collapse.secondary.accDropdownClosed {
                display: none !important;
            }

            > li:focus .collapse.secondary {
                display: flex;
            }
        }

        .secondary {
            width: 100%;
            position: absolute;
            left: 0;
            z-index: $zindex-higher;
            background: $white;
            padding: 42px 0 40px;

            &:hover {
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
            }

            &:focus {
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                overflow: scroll;
            }

            .collapse {
                display: block;
            }

            > .nav-item,
            > .menu-banner {
                width: 16.66667%;
                margin-right: 21px;
            }

            .menu-banner {
                img {
                    width: 100%;
                }
            }
        }

        .show-divider {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                border-right: 1px solid $light-gray;
            }
        }

        .collection-category {
            ul {
                &.tertiary {
                    margin-top: 0;

                    li:first-child {
                        padding-top: 0;
                    }
                }
            }
        }

        .navbar-nav {
            &.primary {
                > .nav-item {
                    > .nav-link {
                        padding: 0.8rem 0;
                        margin-right: 44px;
                    }
                }
            }

            .nav-primary {
                @include fontSize($font-body3);

                margin-right: 44px;
                line-height: 1;
                border: none;
                padding: 16px 0 6px;

                &.category-border {
                    @include underlineOnClass($red, $flag: 'active');

                    padding: 16px 0 6px;
                }
            }

            .nav-secondary {
                @include fontSize($font-body3);

                font-weight: 600;
                padding: 0;

                @include underlineOnHover(1px, 1.3);

                &:hover {
                    span.ctaArrow {
                        transform: translateX(50%);
                    }
                }
            }

            .secondary {
                .menu-banner {
                    display: inline-block;
                }

                > .nav-item {
                    padding-left: 14px;

                    &.groupedCategories {
                        width: fit-content;
                        max-width: 460px;
                    }

                    .twocolumns {
                        display: block;
                        width: max-content;
                        column-count: 2;
                        column-width: 189px;
                    }

                    @include media-breakpoint-down(md) {
                        padding: 0;
                    }

                    > .nav-link {
                        white-space: normal;

                        @include underlineOnHover(#d31334, 1px, 1.5);
                    }
                }
            }

            .tertiary {
                .nav-item {
                    padding: 16px 20px 0 0;
                    max-width: 100%;
                }

                .nav-link {
                    letter-spacing: normal;
                    white-space: normal;
                    @include underlineOnHover(#d31334, 2px, 1.8);
                }
            }
        }
    }

    .open-menu {
        width: 100%;
        z-index: $zindex-medium;

        &.open-menu-pagemask {
            background-color: rgba(0, 0, 0, 0.5);
            -webkit-backdrop-filter: blur(18.5px);
            backdrop-filter: blur(18.5px);
        }

        &.open-menu-menubackground {
            background: $white;
        }
    }
}

@media screen and (min-width: $medium-device-min-width) and (max-width: $large-device-max-width) {
    .menu {
        .navbar-nav {
            padding: 0;

            &.primary {
                padding: 0;

                > .nav-item {
                    overflow-wrap: break-word;
                    word-break: break-word;

                    > .nav-link {
                        margin-right: 28px;
                    }
                }
            }

            .nav-primary {
                margin-right: 12px;
                margin-left: 12px;
            }
        }
    }
}

@include media-breakpoint-down(xs) {
    .L3Mobile {
        display: block !important;
    }

    .L3Desktop {
        display: none !important;
    }

    .menu .navbar {
        padding: 0;

        .primary {
            .nav-item {
                .nav-primary {
                    padding-left: 16px;
                    padding-right: 16px;
                    color: #000;
                    letter-spacing: 0;
                    line-height: 44px;
                    @include fontSize($font-xsmall);

                    @include media-breakpoint-down(md) {
                        line-height: 24px;
                    }
                }
            }
        }
    }

    .nav-header {
        padding: 16px;
    }

    li.accordion.nav-item.dropdown-selector.country-selector,
    li.accordion.nav-item.dropdown-selector.language-selector {
        padding-left: 16px;
        padding-right: 16px;
        outline: none !important;
    }

    .menu-toggleable-left.in .nav-item + .nav-item {
        border-top: none;
    }
}

@include media-breakpoint-down(md) {
    li.accordion.nav-item.dropdown-selector.country-selector {
        .nav-header {
            width: 100%;
            display: flex;
            align-items: center;

            .flag-icon {
                height: 16px;
            }

            span {
                margin-left: 10px;
            }
        }
    }

    li.accordion.nav-item.dropdown-selector.language-selector {
        display: flex;

        .list-link:not(:last-child) {
            padding-right: 6px;
        }
    }
}

@include media-breakpoint-down(md) {
    .L3Mobile {
        display: block !important;
    }

    .L3Desktop {
        display: none !important;
    }

    .collections_data {
        padding-bottom: 30px;

        .nav-item {
            @include fontSize($font-body2);

            letter-spacing: 0;
            line-height: 20px;
            padding-top: 12px;
            padding-bottom: 12px;
        }

        .nav-link {
            padding: 0;
        }
    }
}

@include media-breakpoint-up(lg) {
    .L3Desktop {
        display: block !important;
    }

    .L3Mobile {
        display: none !important;
    }

    .descVal {
        @include fontSize($font-body3);

        font-weight: 600;
        letter-spacing: 1.2px;
        line-height: 20px;
        text-transform: uppercase;

        &:hover .ctaArrow {
            transform: translateX(50%);
        }
    }

    .activity_title {
        text-transform: uppercase;
        padding-left: 24px;
        padding-top: 20px;
        padding-bottom: 21px;
        padding-right: 0;
        font-weight: 600;
        letter-spacing: 1.2px;
        line-height: 20px;
        width: 16.66667%;
        @include fontSize($font-body3);
    }

    .activities {
        display: flex;

        .show-divider {
            &::after {
                top: 10px;
                height: 68%;
                right: 0;
            }
        }
    }

    .activity_data {
        background-color: $gray;
        margin-top: 32px;
        width: 100%;

        li.activity_item {
            display: inline;
            list-style: none;
            margin-right: 31px;
            padding-top: 20px;
            padding-bottom: 20px;

            &:first-of-type {
                padding-left: 58px;
            }

            a.nav-activity,
            a.nav-link {
                display: block;
                text-align: center;
                text-decoration: none;
                letter-spacing: 0;
                line-height: 20px;
                padding: 0 !important;
                @include fontSize($font-body2);

                &:hover {
                    border-bottom: 1px solid #d31334;
                }
            }

            .shopAll_title {
                @include fontSize($font-body3);

                font-weight: 600;
                letter-spacing: 1.2px;
                line-height: 20px;
            }
        }
    }

    .shopAll {
        margin-left: auto;
        text-transform: uppercase;

        &:hover .ctaArrow {
            transform: translateX(50%);
        }
    }

    .activity_bar {
        position: absolute;
        bottom: -65px;
        left: 0;
        width: 100%;
    }

    .l2ctaArrow {
        &:hover .ctaArrow {
            transform: translateX(50%);
        }

        a.nav-secondary:focus + .ctaArrow {
            transform: translateX(50%);
        }
    }
}

@include media-breakpoint-down(md) {
    .shopAllMobile_title {
        @include fontSize($font-body3);

        font-weight: 600;
        letter-spacing: 1.2px;
        line-height: 20px;
    }

    .shopAllMobile {
        margin-left: 16px;
        padding-left: 16px;
        text-transform: uppercase;
        padding-bottom: 30px;

        &:hover .ctaArrow {
            transform: translateX(50%);
        }
    }
}

.nav-promo-tile {
    width: 30%;

    @include media-breakpoint-up(lg) {
        position: absolute;
        right: 0;
    }

    @include media-breakpoint-down(md) {
        width: 100%;
        padding-bottom: 24.5px;
        border-bottom: 1px solid #d5d5d5;
    }

    .col-md-4 {
        flex: 0 0 16.66667%;
        max-width: 100%;
    }

    .promo-tile-element {
        .image-wrapper {
            img {
                border-radius: 4px;
                margin-bottom: 5px;
            }

            .promo-tile-title {
                padding: 5px 0 4px;
                text-align: left;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 20px;
                margin-bottom: 0;
                @include fontSize($font-xsmall);
            }

            .promoBody {
                display: block;
                text-align: left;

                @include media-breakpoint-down(md) {
                    display: none;
                }
            }

            .promoCta {
                padding-top: 8px;
                text-align: left;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 20px;
                @include fontSize($font-body2);

                @include media-breakpoint-up(lg) {
                    span:first-child {
                        width: fit-content;
                    }
                }

                @include media-breakpoint-down(md) {
                    .ctaArrow {
                        display: inline;
                    }
                }

                &:hover .ctaArrow {
                    transform: translateX(50%);
                }
            }
        }
    }
}

.ctaArrow {
    color: #d31334;
    margin-left: 5px;
    transition: 0.2s ease-in;
}

li.shopAllMobile.community {
    display: none;
}

li.nav-item {
    @include media-breakpoint-down(md) {
        outline: none;

        &.country-selector {
            .icon-expand {
                display: block;
            }
        }
    }
}

@include media-breakpoint-down(xs) {
    .navbar {
        .menu-group {
            .navbar-nav {
                .secondary-nav-links {
                    padding-top: 24px;
                    padding-bottom: 0;
                }

                .normal-link {
                    .nav-link {
                        padding-top: 24px;
                        padding-bottom: 0;
                    }

                    &:nth-child(4) {
                        .nav-link {
                            padding-bottom: 24px;
                        }
                    }
                }
            }
        }
    }

    .menu {
        .navbar-nav {
            .nav-secondary {
                letter-spacing: normal;
            }
        }
    }
}

// CSS for added to cart modal for Quick Add on CLP
div[data-action=Search-Show] ~ #addedToCartModal {
    &.modal-extended {
        .product-label {
            .list-price {
                display: inline-block;
                line-height: 25px;
                color: $medium-gray;
                text-decoration: line-through;
                font-weight: 400;
                padding-left: 5px;
                border: 0;
                @include fontSize($font-body1);

                &:empty {
                    display: none;
                }
            }
        }
    }
}

//Page Designer driven mega nav styling 
.pdMegaNav {
    @include media-breakpoint-down(md) {
        li.accordion.nav-item.dropdown-selector.country-selector {
            width: 100%;
        }

        .collections_data {
            .isCollections.collapse {
                display: block;
            }
        }

        .secondary {
            &.show {
                display: flex !important;
                flex-direction: column;
            }
        }

        .primary-nav-item {
            &.highlighted {
                .nav-primary {
                    border-left: 4px solid $fireEngine-red;
                    background-color: $gray;
                }
            }

            .nav-primary {
                padding-left: 16px;
                outline-offset: -2px;
            }
        }
    }
    @include media-breakpoint-up(lg) {
        .activities {
            &.collapse {
                display: flex !important;
            }
        }

        .activity_data {
            .experience-menuTitle {
                display: none;
            }

            li.activity_item:first-of-type {
                width: 223px;
                padding: 0;
                position: relative;
            }
        }

        .activity_title {
            margin: 0;
            padding: 20px 0 20px 42px;
            display: inline-block;
            width: 100%;
        }

        .collection-category {
            ul {
                ul.tertiary.collapse:not(.isCollections) {
                    li.nav-item:first-child {
                        padding: 16px 20px 0 0;
                    }
                }
            }
        }

        .navbar-nav {
            .primary-nav-item {
                &.highlighted {
                    .nav-primary {
                        @include underlineOnClass($red, $flag: 'active');

                        padding: 16px 0 6px;
                    }
                }
            }
        }
    }

    .nav-link {
        &.highlighted {
            color: $fireEngine-red;
            font-weight: bold;
        }
    }

    .navbar-nav {
        .tertiary {
            .nav-link {
                &.highlighted {
                    color: $fireEngine-red;
                    font-weight: bold;
                }
            }
        }
    }

    .activities {
        &.collapse {
            @include media-breakpoint-up(lg) {
                display: flex;
            }
        }

        .activity_item {
            @include media-breakpoint-up(lg) {
                a.nav-link {
                    display: block;
                    text-align: center;
                    text-decoration: none;
                    letter-spacing: 0;
                    line-height: 20px;
                    padding: 0 !important;
                    border-bottom: 1px solid transparent;
                    @include fontSize($font-body2);

                    &:hover {
                        border-bottom-color: #d31334;
                        background: none;
                    }
                }
            }
        }
    }

    ul.activities li:last-child {
        text-align: right;
        right: 0;
        position: absolute;
    }

    .activity_data {
        li.activity_item {
            @include media-breakpoint-down(xs) {
                padding-left: 16px;
                padding-right: 16px;
                color: #000;
                letter-spacing: 0;
                line-height: 44px;
                @include fontSize($font-xsmall);
            }
        }
    }
}

.pdMobileMegaNavWrapper {
    .activities {
        li:first-child,
        li:last-child {
            display: none;
        }

        .activity_item {
            position: relative;
            text-align: left;
            margin-left: 16px !important;
            margin-right: 16px;
            padding-top: 16px;
        }

        li:nth-child(2) {
            padding-top: 0;
        }
    }

    .shopAll_title {
        @include fontSize($font-body3);

        font-weight: 600;
        letter-spacing: 1.2px;
        line-height: 20px;
    }

    .collections_data:not(.isCollections) {
        ul.tertiary {
            li.nav-item:first-child {
                padding-top: 0;
            }

            .nav-link {
                padding-bottom: 8px;
            }
        }
    }

    .collections_data {
        &.isCollections {
            order: 5;
        }

        &:not(.isCollections) {
            padding-bottom: 0;
            margin: 0 16px;
            border-bottom: 1px solid $gainsboro-gray;
        }
    }

    .pdShopAllMobileLink {
        order: 6;
        display: none;

        &.showShopAllLinks {
            display: block;
        }
    }
}

html:lang(zh) * {
    @include media-breakpoint-up('md') {
        .menu {
            .navbar-nav {
                .nav-primary {
                    font-size: 16px;
                }
            }
        }
    }
}

html {
    &:lang(ja),
    &:lang(ko),
    &:lang(zh) {
        .nav-promo-tile {
            .promo-tile-element {
                .image-wrapper {
                    .promoBody {
                        line-height: 22px;
                    }
                }
            }
        }
    }
}
